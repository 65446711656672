import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

const Post = styled.article`
  margin: 64px auto;
  max-width: 1600px;
  padding: 0 16px;

  h2:first-of-type {
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  div:first-of-type {
    max-height: 465px;
  }

  p:first-of-type {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin: 128px auto;
  }
`

export default function BlogPostTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, date, author, cover, excerpt },
      body,
    },
  } = data

  return (
    <Layout>
      <Seo title={title} description={excerpt} />
      <Post>
        <GatsbyImage image={getImage(cover)} alt={title} />

        <h2>{title}</h2>
        <p>
          {author ? author : "Pikes Peak"} &bull; {date}{" "}
        </p>
        <MDXRenderer>{body}</MDXRenderer>
      </Post>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        author
        cover {
          childImageSharp {
            gatsbyImageData(
              webpOptions: { quality: 80 }
              layout: FULL_WIDTH
              quality: 70
            )
          }
        }
        excerpt
      }
      body
    }
  }
`
